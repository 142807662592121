// @flow
import React from 'react';
import Layout from '../../components/layout';
import G from '../../components/glossary-link';
import Link from '../../components/link';
import type { FrontMatter } from '../../utils/types';
import { withFrontMatter } from '../../components';
import route from '../../routes';

type Props = { frontMatter: FrontMatter };

class AcaAspirantSkillsChecklist extends React.Component<Props> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>
          <Link to="https://www.canyoneering.net/wp-content/uploads/2020/05/Aspirant-Skills-Checklist-4-22-20.pdf">
            Original document
          </Link>{' '}
          from the{' '}
          <G displayTerm={true} id="american-canyoneering-association">
            ACA
          </G>
          's{' '}
          <Link to="https://www.canyoneering.net/resources/">
            resource page
          </Link>
          .
        </p>
        <p>
          OBJECTIVE: Through training and mentoring, increase of technical
          personal skill sets and expanded situational awareness and to
          introduce group leadership roles, associated technical skills and
          instructional techniques.
        </p>
        <p>
          NOTE: Skills Checklists are cumulative in nature. Students must know
          all the skills from the '
          <Link to={route.aca_core_skills_checklist}>Core</Link>' Checklist in
          addition to the skills listed here.
        </p>

        <h2>Navigation, Map Reading, Canyon Topos</h2>
        <ul>
          <li>
            Read contour lines on a <G>topographic map</G>; identify terrain
            features (hills, <G>saddles</G>, <G>ridges</G>, <G>drainages</G>),
            determine relative steepness of grade, identify potential high
            ground and canyon escape routes.
          </li>
          <li>
            Identify relative size of a canyon <G>watershed</G> using topo map.
          </li>
          <li>Orient a map with compass and terrain.</li>
          <li>
            Use <G>GPS</G> to determine grid coordinates, mark waypoints and go
            to waypoints.
          </li>
          <li>Plot grid coordinates on a topographic map.</li>
          <li>Prepare and use a canyon topo.</li>
        </ul>

        <h2>Knot Craft</h2>
        <ul>
          <li>
            Tie and inspect; <G>Frost knot</G> in webbing.
          </li>
          <li>Tie and inspect; Ladder with webbing.</li>
          <li>Tie and inspect; Bowline with Yosemite finish.</li>
          <li>
            Tie and inspect Inline knots; (i.e. Alpine butterfly, Directional
            Figure Eight).
          </li>
          <li>
            Tie and inspect; (a) <G>Munter hitch</G>, (b) <G>Mule hitch</G> or
            two half hitches.
          </li>
          <li>
            Tie and inspect; (a) Klemheist, (b) Asymmetric Prusik (with a{' '}
            <G>VT Prusik</G> or equivalent).
          </li>
        </ul>

        <h2>Anchors</h2>
        <ul>
          <li>
            Understand and apply good principles of <G>anchor</G> location;{' '}
            <G>DEAR</G> (Dry, Efficient, Accessible,{' '}
            <G id="pull">Rope Retrieval</G>).
          </li>
          <li>
            Understand and apply good principles of anchor identification,
            construction, friction, and testing.
          </li>
          <li>
            Identify, Evaluate, and rig single-point <G>natural anchor</G> (i.e.
            tree, boulder, arch,) a). using a{' '}
            <G id="cinch-wrap">cinching wrap</G> - <G>wrap 3 pull 2</G>, b).{' '}
            <G>redundant wrap</G> (i.e. <G id="basket-hitch">Basket</G> with{' '}
            <G>overhand knot</G> at <G>focal point</G>) Explain the pros and
            cons and demonstrate how to safely test single point anchors.
          </li>
          <li>
            Identify, construct, rig, and evaluate/test multi-point natural
            anchors using <G>EARNEST</G>.
          </li>
          <li>
            Rope Retrieval: Mitigate standard rope retrieval challenges with "
            <G>courtesy loop</G>".
          </li>
          <li>
            Rope Retrieval: Anchor a partially retrieved rope (<G>pull side</G>
            ).
          </li>
          <li>
            Rope Retrieval: Ascend a partially retrieved rope (
            <G>rappel side</G>).
          </li>
        </ul>

        <h2>Rigging</h2>
        <ul>
          <li>
            Set up and use{' '}
            <G id="releasable-rigging-system">releasable single rope system</G>{' '}
            (contingency), including three parts: (1) friction mechanism to
            allow controlled lowering, (2) tie-off that is releasable under
            tension, and (3) identifying risks and setting safety to mitigate
            those risks.
          </li>
          <li>
            Set up and use <G id="static-block">blocked rope systems</G> (
            <G id="biner-block">carabiner blocks</G>) for rappel. Explain the
            pros and hazards of using blocks and steps that should be taken to
            avoid accidents.
          </li>
          <li>
            Set up and use <G id="double-rope-technique">double rope system</G>{' '}
            (<G>Toss ’n Go</G>) for rappel. Explain the pros and cons of double
            rope systems (Toss ’n Go).
          </li>
          <li>
            Describe methods for safely{' '}
            <G id="set-rope-length">setting initial rope lengths</G> for wet and
            dry landings i.e. estimating height, rigging releasable, lowering
            first person, above water level.
          </li>
          <li>
            Demonstrate how to safely and efficiently reset / adjust rope
            length.
          </li>
          <li>
            Identify and demonstrate how to change abrasion points as needed to
            protect rope/webbing from abrasion and soft rock from{' '}
            <G>rope grooves</G>/damage.
          </li>
          <li>
            Set up and use <G>Static Courtesy Rigging</G> to facilitate
            trouble-free rope retrieval. Explain purpose, proper usage, and
            application.
          </li>
          <li>Set up and use a safety line to protect a traverse.</li>
        </ul>

        <h2>Belaying</h2>
        <ul>
          <li>
            Set up and use a <G>Self belay</G> using alternate methods /
            techniques (<G id="autoblock">auto-block</G>). Understand and
            explain the pros and cons of using a self-belay.
          </li>
          <li>
            Belay a climber; climbing down, a.) using rope from{' '}
            <G id="meat-anchor">human anchor</G> (hip belay) 10ft or less, b.)
            using rope from fixed anchor.
          </li>
        </ul>

        <h2>Signals / Communication</h2>
        <ul>
          <li>
            Understand and use proper whistle signals.{' '}
            <Link to="https://www.canyoneering.net/docs/signals.pdf">
              https://www.canyoneering.net/docs/signals.pdf
            </Link>
          </li>
          <li>
            Use Radios to communicate verbal commands for belaying, rappelling.
          </li>
        </ul>

        <h2>On Rope Techniques, Companion Rescue</h2>
        <ul>
          <li>
            <G>Ascend</G> a fixed rope using{' '}
            <G id="friction-hitch">friction hitches</G>; single strand, double
            strand.
          </li>
          <li>
            Ascend a fixed rope using <G id="ascender">mechanical ascenders</G>;
            single strand.
          </li>
          <li>Transition from rappel to ascend and from ascend to rappel.</li>
          <li>
            <G>Pass a knot</G> while rappelling. Pass a knot while ascending.
          </li>
          <li>
            Demonstrate rappelling on a <G>Guided Rappel</G> (set up by a
            competent person). Explain safety concerns and mitigation.
          </li>
          <li>
            Safely lower an incapacitated rappeller via <G>Bottom Belay</G>.
          </li>
          <li>Rappel with pack securely hanging from harness.</li>
          <li>
            Demonstrate safe rappel start off of extended courtesy rigging.
          </li>
          <li>
            Safely and effectively <G>zip</G> line gear over/around obstacle.
            Describe scenarios for usage.
          </li>
          <li>
            Shift rappeller from <G>rappel line</G> to <G>top rope belay</G>{' '}
            line.
          </li>
          <li>
            Release contingency block and lower a person, using{' '}
            <G>hands free backup</G> (i.e. friction hitch attached to the anchor
            or to the <G>harness</G> of the person in control of lowering;
            avoiding rope grooves in soft rock)
          </li>
          <li>
            Perform controlled rappel up to 200'+ adding friction mid-rappel;
            communication - whistles or radios; hanging packs.
          </li>
        </ul>

        <h2>Canyon Leadership</h2>
        <ul>
          <li>
            Identify and assess <G>flash flood</G> risks associated with a
            selected canyon.
          </li>
          <li>
            Perform initial gear-up 'Buddy Checks' (<G>Safety check</G>) and at
            'Buddy Checks' every rappel.
          </li>
          <li>
            Canyon Basic First Aid / CPR: a) Stabilize injury / Treat for Shock;
            b) Go for help; c) Basic injury management.
          </li>
        </ul>

        <h2>Swimming, Jumping, Waterfalls</h2>
        <ul>
          <li>Swim 50 yards with gear, without floatation.</li>
          <li>
            Jumping into water (Max distance 8ft./8ft. Min depth) identify
            hazards/depth check, correct body position.
          </li>
          <li>
            Describe and safely demonstrate basic Waterfall Rappelling
            Techniques.
          </li>
          <li>
            Describe basic waterfall/stream hazards such as{' '}
            <G>foot entrapments</G>, <G>strainers</G>, <G>siphons</G>,{' '}
            <G>undercuts</G>, <G>recirculating currents</G>,{' '}
            <G>rope entanglement</G> and appropriate methods for mitigating /
            dealing with them.
          </li>
        </ul>

        <h3>Recommendations After Training</h3>
        <ul>
          <li>
            Practice technical skills in low-risk conditions, such as on clean
            and low angle "slab" type environments or on vertical terrain with
            an effective Top or Bottom (Fireman’s) Belay.
          </li>
          <li>
            Increase technical skill sets, expand situational awareness, and
            begin introduction to Canyoneering Leadership all through skill
            practice sessions, workshops, and mentored canyoneering
            opportunities.
          </li>
        </ul>
      </Layout>
    );
  }
}

export default withFrontMatter('aspirant-skills-checklist')(
  AcaAspirantSkillsChecklist
);
